import React from "react";
import { Link } from "gatsby";
import { Box, Heading, Stack, Text } from "@chakra-ui/react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const LinkStyles = {
  textDecoration: "none",
  color: "black",
};

const PostList = (props) => {
  const node = props.node;
  const image = getImage(node.frontmatter.hero_image);
  return (
    <Box
      key={node.id}
      borderRadius={"1px"}
      backgroundColor={"gray.200"}
      mb={"10px"}
      shadow={"md"}
    >
      <Link to={`/blog/${node.slug}`} style={LinkStyles}>
        <Stack direction="column" maxW="md">
          <Box>
            <GatsbyImage image={image} alt={node.frontmatter.hero_image_alt} />
          </Box>
          <Heading color={"gray.700"} fontSize={"lg"}>
            {node.frontmatter.title}
          </Heading>
          <Text color={"gray.500"}>{node.frontmatter.date}</Text>
        </Stack>
      </Link>
    </Box>
  );
};
export default PostList;

import * as React from "react";
import Header from "./header";
import { Box } from "@chakra-ui/react";
import Seo from "./seo";
import PostList from "./PostList";

const BlogsLayout = ({ data }) => {
  return (
    <>
      <Seo />
      <Box m={"10px"} mx="auto" maxW="lg">
        <Header />
        <Box maxW="md" mx="auto">
          {data.allMdx.nodes.map((node) => (
            <PostList node={node} />
          ))}
        </Box>
      </Box>
    </>
  );
};
export default BlogsLayout;

import * as React from "react";
import { graphql } from "gatsby";
import BlogsLayout from "../components/blogslayout";

const BlogPage = ({ data }) => {
  return <BlogsLayout data={data} />;
};

export const query = graphql`
  query {
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        frontmatter {
          title
          date(formatString: "YYYY.MM.DD")
          category
          hero_image_alt
          hero_image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        id
        slug
        body
      }
    }
  }
`;

export default BlogPage;
